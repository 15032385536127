:root {
}

/* *{ line-height: 1em } */

.xxl { font-size: 8.0vh; line-height: .7 }
.xl  { font-size: 4.0vh; line-height: .9 }
.l   { font-size: 2.8vh }
.m   { font-size: 2.2vh }
.s   { font-size: 2.0vh }

.xw { font-weight: 800 }
.w  { font-weight: 600 }
.t  { font-weight: 400 }
.xt { font-weight: 300 }

.condensed { letter-spacing: -.1em }

.wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  width: 100vw; height: 100vh;
  overflow: hidden;

  background: 
    linear-gradient(
      rgb(0, 15, 15) 0 40%,
      #0b0b0bff 50%,
      rgba(0, 0, 0, 0.92) 50%,
      rgba(255, 0, 0, 0.8) 66.6%
    ),
    linear-gradient(
      15deg, 
      rgba(0, 0, 255, 0.4), 
      transparent 33.3%
    ),
    linear-gradient(
      -15deg,
      rgba(0, 255, 0, 0.4), 
      transparent 33.3%
    );
  background-blend-mode: lighten;

  /* background: 
    linear-gradient(
      rgb(0, 15, 15) 0 40%,
      #0b0b0bff 50%,
      rgba(0, 0, 0, 0.92) 50%,
      #ffffff11 66.6%
    ); */

  /* filter: hue-rotate(180deg); */

  filter: none;
  transition: 
    filter 150ms ease-in,
    transform 150ms ease-in;
}

.wrap--blurred { 
  filter: saturate(.25) brightness(.75) blur(30px);
  transform: scale(1.05);
 }

.page__top {
  height: fit-content;
  position: absolute;
  
  top: 5vh; left: 5vh; right: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page__title {
  color: white;
}

.button--cart {
  position: relative;
  scale: 1.8;
  margin: 23px 23px; 
  background-color: #ccc !important;
}

.button--indicator {
  font-family:'Segoe UI';
  position: absolute;
  bottom: -40%; right: -40%;
  scale: 0.25;
  background-color: #f32020 !important;
  color: #fff !important; 
}


.product {
  /* width: 1000px; height: 1000px; */
  width: 100vh; height: 100vh;
  max-width: 100vw; max-height: 100vw;

  position: relative;
}

.product__canvas {
  border-radius: 50%;

  z-index: 2;

  /* background: radial-gradient(transparent 50%, black 70%); */
}

.product__description {
  width: 42vh;

  position: absolute;
  /* bottom: 200px; */
  bottom: 25vh;
  /* left: 10px; */
  z-index: 1;

  color: white;
}

.slider {
  height: 95vh;
  padding: 2.5vh;
  box-sizing: border-box;

  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  gap: 5vh;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;    /* Firefox */

  /* background-color: #00ffff55; */
}

.slider::-webkit-scrollbar {
  display: none;
}

.card {
  min-width: 31.875vh; min-height: 42.5vh;
  box-sizing: border-box;
  z-index: 3;

  /* box-shadow: -10px 10px 10px #00000066; */
  box-shadow: 0 0 20px 5px #00ffff55;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.card__view {
  width: 100%; height: 75%;

  background: linear-gradient(#222, #444);
  border-radius: 5px 5px 0 0;
}

.card__content {
  position: relative;

  width: 100%; height: 25%;

  background-color: #222;
  color: white;
  border-radius: 0 0 5px 5px;

  display: flex;
}

.card__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card__column--details {
  width: 40%; height: 100%;

  padding-left: 7.5%;
  align-items: flex-start;
  /* gap: 5px; */

  /* font-size: 2.2vh; */
}

.card__column--price {
  width: 60%; height: 100%;
  overflow: hidden;
  
  padding-right: 7.5%;
  align-items: flex-end;

  /* font-size: 2.8vh; */
}

.modal__backdrop {
  position: absolute;
  width: 100vw; height: 100vh;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  /* backdrop-filter: none;
  transition: backdrop-filter 500ms ease; */
}

/* .modal__backdrop--filtered {
  backdrop-filter: saturate(.2) brightness(.2) blur(20px);
  transition: backdrop-filter 100ms;
} */

.modal__box {
  width: 500px; height: 600px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #222;
  border: 1px solid #444;
}

.modal__bar {
  width: 100%; height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* background-color: #ff000033; */
  border-bottom: 1px solid #444;
}

.modal__title {
  margin-left: 15px;
  color: #bbb;
}

.cart {
  margin: 15px;
  color: white;
}

.cart__item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 10px 10px 15px;
  border-bottom: 1px solid #444;
}

.button {
  min-width: 5vh; min-height: 5vh;
  font-size: 3vh;
  line-height: 5vh;

  text-align: center;
  border-radius: 50%;

  box-shadow: 0 0 10px 0 #ffffff22;
  background-color: #1b1b1b;
  color: #555;

  cursor: pointer;
  z-index: 20;
}

.button:active {
  filter: invert(1);
}

.button--exit { 
  margin: 0 15px 0 0; 
}

.button--exit::before { content: "🗙" }

.button--add {
  position: absolute;
  right: -2.5vh; top: -2.5vh;

  background-color: #ccc;
  color: #000;
}

.button--remove {
  scale: 1;
  margin: 0 0 0 15px; 
}

.button--remove::before { content: "−" }

.credit {
  position: absolute;
  left: 10px; bottom: 10px;
  color: white;
}

a { text-decoration: none; color: white }

/* @media only screen and (max-width: 1500px) { */
@media (orientation: portrait) {

  .xxl { font-size: 6.0vw }
  .xl  { font-size: 3.0vw }
  .l   { font-size: 2.5vw }
  .m   { font-size: 2.0vw }
  .s   { font-size: 1.8vh }

  .wrap { 
    flex-direction: column; 
    justify-content: flex-end; 
    align-items: center;
  }

  .page__top {
    left: 5vw; top: 5vw; right: 5vw;
  }

  .button {
    min-width: 5vw; min-height: 5vw;
    line-height: 5vw;
    font-size: 3vw;
  }

  .button--add {
    right: -2.5vw; top: -2.5vw;
  }

  .product { 
    position: absolute;  
    z-index: 1;
    top: 50%; 
    transform: translate(0, -50%);
  }

  .product__description {
    width: 38vw;
    left: 5vw;
    bottom: 25vw;
  }

  .slider { 
    width: 95vw; height: fit-content;
    padding: 2.5vw 2.5vw 15vw 2.5vw;
    
    gap: 5vw;

    flex-direction: row;
    align-self: auto;

    overflow-x: scroll;
    overflow-y: hidden;

    /* background-color: #00ffff55; */
  }

  .card {
    min-width: 26.666vw; min-height: 35.555vw;
  }
}
